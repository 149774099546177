import * as React from "react";
import { Link, useParams, useRoutes } from "react-router-dom";
import { Container, Nav, NavItem, NavLink } from 'reactstrap';
import DCACalculator from "./DCACalculator";
import Screener from "./Screener/Screener";
import TradeLogindex from "./Tradelog/Tradelog";
import { TradelogContext } from "./Tradelog/context";
export default function App() {
  let element = useRoutes([
    { path: "/", element: <Layout><Screener endpoint="open-trades" interval={5 * 60 * 1000} title="Open trades" /> </Layout>},
    { path: "/open-trades", element: <Layout><Screener endpoint="open-trades" interval={5 * 60 * 1000} title="Open trades" /> </Layout>},
    { path: "screener", element: <Layout><Screener endpoint="screener" interval={10 * 60 * 1000}  /> </Layout>},
    {
      path: "log",
      element: <Layout><TradeLogindex/></Layout>,
      children: [
        { index: true, element: <Layout><TradeLogindex /></Layout> },
        {path: "trade/:trade_id", element: <Layout header={false}><TradeLogindex/></Layout>},
      ],
    },
    {
      path: "dca-calculator",
      element: <Layout><DCACalculator /></Layout>,
    }
  ]);

  return <div>{element}</div>;
}


const Layout = ({ children, header = true }) => {
  return (
    <>
      {header && <Header /> }
      <main >{children}</main>
      {/* <Footer /> */}
    </>
  );
};


// Header.js
const Header = () => {
  const api = React.useContext(TradelogContext);
  const params = useParams();

  if(params.trade_id){
    return null;
  }

  return (
    <Container fluid={api.fullscreen}>
      <Nav className="d-flex">
      <NavItem className="ms-auto">
          <NavLink className="text-white" tag={Link} to="/open-trades">Open Trades</NavLink>
        </NavItem>
        <NavItem className="ms-">
          <NavLink className="text-white" tag={Link} to="/screener">Screener</NavLink>
        </NavItem>
        <NavItem>
          <NavLink className="text-white" tag={Link} to="/log">Log</NavLink>
        </NavItem>
        <NavItem>
          <NavLink className="text-white" tag={Link} to="/dca-calculator">DCA Calculator</NavLink>
        </NavItem>
      </Nav>
    </Container>
  );
};

// // Footer.js
// const Footer = () => {
//   return (
//     <footer>
//       <p>Copyright © Your Company 2023</p>
//       {/* Other footer details */}
//     </footer>
//   );
// };