import { default as Dayjs, default as dayjs } from 'dayjs';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Col, Container, FormGroup, Input, Label, Row } from 'reactstrap';
import { FirebaseAuthContext } from '../SignIn';
import { StatusBadge } from '../Tradelog/Tradelog';
import { TradelogContext } from '../Tradelog/context';
import { callApi, getFilledArrayOrDefault } from '../util';
import CandleChart from './CandleChart';
import OpenPositions, { TradePositionsTable } from './Positions';
import PriceChart from './PriceChart';
// Get the current time in Unix format
const now = dayjs().unix();

export function ForexPair({ pair }) {
    return (
        <span>
            <span className={`fx fx-${pair.slice(0, 3)}`}>{pair.slice(0, 3)}</span>
            <span className={`fx fx-${pair.slice(3)}`}>{pair.slice(3)}</span>
        </span>
    );
}

const signals = (type, asset, minZScore) => (
    <div className="d-flex align-items-center">
        <span style={{ width: "3rem" }}>{type}</span>
        <span style={{ width: "4rem" }} className={Math.abs(asset[`z_score_${type}`]) < 2 ? "opacity-70" : Math.abs(asset[`z_score_${type}`]) > 3 ? "text-warning fw-bold" : ""}>
            {asset[`z_score_${type}`].toFixed(2)}
        </span>
        <span>{asset.z_scores_high_50.map((i, index) => {
            const high = asset["z_scores_high_" + type][index]
            const low = Math.abs(asset["z_scores_low_" + type][index])
            if (high >= 3 || low >= 3) {
                return '📀'
            }

            if (high >= minZScore) {
                return '🔴'
            }
            if (low >= minZScore) {
                return '🟢'
            }

            if (asset.recent_prices_in_value_area[index]) {
                return '🟦'
            }

            return '⚪'
        })}</span>
    </div>
);

function useScreenerData(endpoint, interval) {
    const user = useContext(FirebaseAuthContext); // Access the token
    const api = useContext(TradelogContext);
    const [minZScore, setMinZScore] = useState(2.4);
    const [minIndexScore, setMinIndexScore] = useState(55);
    const [filterTypes, setFilterTypes] = useState({ "forex": true, "crypto": true })
    const [screenerData, setScreener] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await callApi(user, "GET", `/capital_com/${endpoint}`);
                setScreener(data);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
        const intervalId = setInterval(fetchData, interval); // Refresh every 5 minutes

        // Cleanup function to clear the interval when the component unmounts
        return () => clearInterval(intervalId);
    }, [user, endpoint, interval]);

    const trades = useMemo(() => api.allTrades.sort((a, b) => b.open_unix - a.open_unix).map(i => ({
        ...i,
        isOlderThan3Weeks: now - i.open_unix > 3 * 7 * 24 * 60 * 60,
        isOlderThan6Weeks: now - i.open_unix > 6 * 7 * 24 * 60 * 60,
        isOlderThen12Weeks: now - i.open_unix > 12 * 7 * 24 * 60 * 60,
        date: dayjs.unix(i.open_unix).format("D MMM"),
        close_date: i.close_unix ? dayjs.unix(i.close_unix).format("D MMM") : null,
    })).map(i => ({ ...i, className: i.isOlderThan6Weeks ? "opacity-40" : (i.isOlderThan3Weeks ? "opacity-70" : "") })), [api.allTrades]);

    const dataWithTrades = useMemo(() => getFilledArrayOrDefault(screenerData).map(i => ({
        ...i,
        trades: trades.filter(j => j.pair === i.pair).slice(0, 4),
        openTradeIds: trades.filter(j => j.pair === i.pair && ["waiting", "unfilled", "filled"].includes(j.status)).map(j => j.trade_id),
        correlatedTrades: trades.filter(j =>
            i.type === "forex" &&
            ["waiting", "unfilled", "filled"].includes(j.status) &&
            j.pair !== i.pair && (
                [i.pair.slice(0, 3), i.pair.slice(-3)].includes(j.pair.slice(0, 3)) ||
                [i.pair.slice(0, 3), i.pair.slice(-3)].includes(j.pair.slice(-3))
            )),
    })), [screenerData, trades]);

    const filteredData = useMemo(() => dataWithTrades.filter(i => (i.index_score >= minIndexScore || i.openTradeIds.length > 0) && filterTypes[i.type]), [dataWithTrades, minIndexScore, filterTypes]);

    const preparedData = useMemo(() => filteredData.sort((a, b) => {
        // Prioritize items with open trades
        if (a.openTradeIds.length > 0 && b.openTradeIds.length === 0) {
            return -1; // a comes before b
        } else if (a.openTradeIds.length === 0 && b.openTradeIds.length > 0) {
            return 1; // b comes before a
        }

        // If both have the same openTradeIds length, sort by index_score
        return b.index_score - a.index_score; // higher index_score first
    }).map(i => ({
        ...i,

        // Calculate Z-scores for recent highs
        z_scores_high_50: i.recent_prices.slice(-12).map(ohlc => (ohlc[1] - i.sma_50) / i.std_dev_50),
        z_scores_high_100: i.recent_prices.slice(-12).map(ohlc => (ohlc[1] - i.sma_100) / i.std_dev_100),
        z_scores_high_200: i.recent_prices.slice(-12).map(ohlc => (ohlc[1] - i.sma_200) / i.std_dev_200),
        z_scores_high_360: i.recent_prices.slice(-12).map(ohlc => (ohlc[1] - i.sma_360) / i.std_dev_360),

        // Calculate Z-scores for recent closes (only last 12 candles)
        z_scores_low_50: i.recent_prices.slice(-12).map(ohlc => (ohlc[2] - i.sma_50) / i.std_dev_50),
        z_scores_low_100: i.recent_prices.slice(-12).map(ohlc => (ohlc[2] - i.sma_100) / i.std_dev_100),
        z_scores_low_200: i.recent_prices.slice(-12).map(ohlc => (ohlc[2] - i.sma_200) / i.std_dev_200),
        z_scores_low_360: i.recent_prices.slice(-12).map(ohlc => (ohlc[2] - i.sma_360) / i.std_dev_360),
        recent_prices_in_value_area: i.recent_prices.slice(-12).filter(ohlc => ohlc[3] >= i.value_area_low && ohlc[3] <= i.value_area_high),
    })),
        [filteredData]);

    const screener = preparedData.map(i => ({
        ...i,
        closedPositions: api.closedPositions.filter(j => i.openTradeIds.includes(j.trade_id)),
        openPositions: api.openPositions.filter(j => i.openTradeIds.includes(j.trade_id) || j.pair === i.pair),
    }));

    return { screener, trades, minZScore, setMinZScore, minIndexScore, setMinIndexScore, filterTypes, setFilterTypes };

}


export default function Screener({endpoint, interval, title = "Screener"}) {
    const { screener, minZScore, setMinZScore, minIndexScore, setMinIndexScore, filterTypes, setFilterTypes } = useScreenerData(endpoint, interval);

    return (
        <div>
           {endpoint === "open-trades" && <OpenPositions data={screener.filter(i => i.openPositions.length > 0)} />}
            <Container className="py-5 px-3">
                <Row className="align-items-center">
                    <Col md={5}>
                        <h3 className="text-white py-3">{title}</h3>
                    </Col>
                    <Col md={3}>
                        <FormGroup>
                            <Label for="index_score" className="text-white">
                                Min Index Score <b><span className="text-info">{Number(minIndexScore).toFixed(1)}</span></b>
                            </Label>
                            <Input
                                id="index_score"
                                name="range"
                                type="range"
                                value={minIndexScore}
                                min={50}
                                max={120}
                                step={2.5}
                                onChange={(e) => setMinIndexScore(e.target.value)}
                            />
                        </FormGroup>
                    </Col>
                    <Col md={3}>
                        <FormGroup>
                            <Label for="zscorerange" className="text-white">
                                Min Z-score <b><span className="text-info">{Number(minZScore).toFixed(1)}</span></b>
                            </Label>
                            <Input
                                id="zscorerange"
                                name="range"
                                type="range"
                                value={minZScore}
                                min={0}
                                max={3.1}
                                step={0.1}
                                onChange={(e) => setMinZScore(e.target.value)}
                            />
                        </FormGroup>
                    </Col>
                    <Col md={1} className="text-end">
                        <FormGroup>
                            <div className="d-flex">

                                <Button
                                    className="me-1 mb-0"
                                    color="primary"
                                    outline={!filterTypes.crypto}
                                    onClick={() => setFilterTypes(prev => ({ ...prev, crypto: !prev.crypto }))}
                                    active={false}
                                >
                                    Crypto
                                </Button>
                                <Button
                                    color="primary mb-0"
                                    outline={!filterTypes.forex}
                                    onClick={() => setFilterTypes(prev => ({ ...prev, forex: !prev.forex }))}
                                    active={false}
                                >
                                    Forex
                                </Button>
                            </div>
                        </FormGroup>
                    </Col>
                </Row>
                {screener.map((asset, index) => <ScreenerAsset key={index} asset={asset} minZScore={minZScore} />)}
            </Container>
        </div>
    );
}

export function OpenTrades() {

}

function ScreenerAsset({ asset, minZScore }) {
    const navigate = useNavigate();
    return <Row className="py-4 border-bottom mb-3">
        <Col md={6} lg={4} xl={3} className="text-white">
            <div className="">
                <a href={`https://www.tradingview.com/chart/YiD4OsYt/?symbol=${asset.symbol}&interval=720`} target="_blank" className="no-link h4" rel="noreferrer">
                    {asset.type === "forex" ? <span>{asset.exchange}:<ForexPair pair={asset.pair} /></span> : asset.symbol}
                </a>
            </div>
            <div className="pt-3">
                <div className="h3 pb-2">
                    <span className="badge rounded-pill bg-info text-dark">{asset.z_index_score.toFixed(0)} Z</span>
                    {asset.value_area > 0 && <span className="ms-2 badge rounded-pill bg-success text-white">+{(asset.value_area + asset.clear_trend).toFixed(0)} VA</span>}
                    {asset.macd_score > 0 && <span className="ms-2 badge rounded-pill bg-success text-white">+{asset.macd_score.toFixed(0)} MACD</span>}
                    {asset.rsi_score > 0 && <span className="ms-2 badge rounded-pill bg-success text-white">+{asset.rsi_score.toFixed(0)} RSI</span>}
                    {asset.is_favorite_pair > 0 && <span className="ms-2 badge rounded-pill bg-success text-white">+{asset.is_favorite_pair.toFixed(0)} FAV</span>}
                </div>
                <div>{signals("360", asset, minZScore)}</div>
                <div className="opacity-70">{signals("200", asset, minZScore)}</div>
                <div className="opacity-40">{signals("100", asset, minZScore)}</div>
                <div className="opacity-40">{signals("50", asset, minZScore)}</div>
            </div>

            <div className="pt-3">
                {(asset.closedPositions.length > 0 || asset.openPositions.length > 0) &&
                    <div className="pt-3 mt-3 border-top">
                        <TradePositionsTable closedPositions={asset.closedPositions} openPositions={asset.openPositions} />
                    </div>
                }
                {asset.trades.length > 0 && <div className="pt-3 border-top mt-3">
                    <div className="h6">Open trades</div>
                    {asset.trades.map(t => (
                        <a href="#" key={t.trade_id} className={`me-1 ${t.className}`} onClick={() => navigate(`/log/trade/${t.trade_id}`)}>
                            <StatusBadge status={t.status} date={t.date} close_date={t.close_date} />
                        </a>
                    ))}
                </div>}
            </div>
            {asset.correlatedTrades.length > 0 && asset.type === "forex" && <div className="border-top pt-3 mt-3">
                <div className="h6">Correlated trades</div>
                {asset.correlatedTrades.map(t => (<div key={t.trade_id} className="pt-3">
                    <a href={`https://www.tradingview.com/chart/YiD4OsYt/?symbol=${asset.symbol}&interval=720`} target="_blank" className="no-link" rel="noreferrer">
                        <ForexPair pair={t.pair} />
                    </a> <a href="#" className={`me-1 ${t.className}`} onClick={() => navigate(`/log/trade/${t.trade_id}`)}><StatusBadge status={t.status} date={t.date} close_date={t.close_date} /></a>
                </div>))}
            </div>}

            {asset['1_hour_50_candles'] && <div className="pt-3 mt-3 border-top" >
                <div >
                    <CandleChart candleData={asset['1_hour_50_candles']} />
                </div>
            </div>}
        </Col>
        <Col md={6} lg={8} xl={9}>
            {asset['4_hour_closes'] && <div className="pt-3 pt-0-md " >
                <PriceChart asset={asset} />
            </div>}

        </Col>
        <Col xs={12} className="text-white opacity-70">
            <div className="pt-3 pt-0-md" ><small>{Dayjs(asset.unix * 1000).format("ddd HH:mm - D MMM")}</small></div>
        </Col>
    </Row>
}