import { faCaretDown, faCaretUp } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import dayjs from 'dayjs';
import React, { useContext } from 'react';
import { Col, Container, Row } from 'reactstrap';
import { TradelogContext } from '../Tradelog/context';
import { getFilledArrayOrDefault } from '../util';

// Get the current time in Unix format
export default function OpenPositions({data}) {
    const api = useContext(TradelogContext);
    if (api.openPositions.length === 0) {
        return null;
    }
    const allPairs = data.map(i => i.pair);
    const unmatchedPositions = api.openPositions.filter(i => allPairs.includes(i.pair) === false);


    const unmatchedPairs = getFilledArrayOrDefault(unmatchedPositions).reduce((acc, pos) => {
        if (acc[pos.pair]) {

            return {
                ...acc,
                [pos.pair]: {
                    ...acc[pos.pair],
                    openPositions: [...acc[pos.pair].openPositions, pos],
                }
            }
        } else {
            return {
                ...acc,
                [pos.pair]: {
                    openPositions: [pos],
                    pair: pos.pair,
                    closedPositions: [],
                }
            }
        }
    }, {});

    const all = [...data, ...Object.values(unmatchedPairs)]


    return <Container className="border-bottom py-5 mb-5">
        <Row className="text-white">
            {all.map((asset) =>
                <Col key={asset.pair} md={6} lg={4} xl={3} className="mt-5">
                    <h4>{asset.pair}</h4>
                   <TradePositionsTable closedPositions={asset.closedPositions} openPositions={asset.openPositions}></TradePositionsTable>
                </Col>
            )}
        </Row>
    </Container>

}

export function TradePositionsTable({ closedPositions, openPositions }) {
    if (closedPositions.length === 0 && openPositions.length === 0) {
        return null
    }
    const totalClosedGross = closedPositions.reduce((acc, pos) => acc + pos.gross_profit, 0);
    const totalClosedNet = closedPositions.reduce((acc, pos) => acc + pos.net_profit, 0);

    const totalOpenGross = openPositions.reduce((acc, pos) => acc + pos.gross_profit, 0);
    const totalOpenNet = openPositions.reduce((acc, pos) => acc + pos.net_profit, 0);

    const totalGross = totalClosedGross + totalOpenGross;
    const totalNet = totalClosedNet + totalOpenNet;

    const money_digits = [...closedPositions, ...openPositions][0].money_digits;
    return <div className="pb-3">
        <table className="w-100">
            <thead>
                <tr>
                    <th>Vol</th>
                    <th>Time</th>
                    <th>Price</th>
                    <th>Gross</th>
                    <th>Net</th>
                </tr>
            </thead>
            {closedPositions.length > 0 &&
                <tbody>
                    {closedPositions.map((pos, i) =>
                        <tr key={i}>
                            <td><VolumeSpan volume={pos.volume} side={pos.trade_side}></VolumeSpan></td>
                            <td>{dayjs(pos.close_timestamp).format("HH:mm D MMM")}</td>
                            <td><PriceSpan price={pos.entry_price} isOpen={false}></PriceSpan></td>
                            <td>
                                <PnLSpan PnL={pos.gross_profit} money_digits={money_digits}></PnLSpan>
                            </td>
                            <td>
                                <PnLSpan PnL={pos.net_profit} money_digits={money_digits} ></PnLSpan>
                            </td>
                        </tr>
                    )}
                    <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td className="fw-bold pb-3">
                            <PnLSpan PnL={totalClosedGross} money_digits={money_digits}></PnLSpan>
                        </td>
                        <td className="fw-bold pb-3">
                            <PnLSpan PnL={totalClosedNet} money_digits={money_digits} ></PnLSpan>
                        </td>
                    </tr>
                </tbody>
            }
            {openPositions.length > 0 &&
                <tbody>
                    {openPositions.map((pos, i) =>
                        <tr key={i}>
                            <td><VolumeSpan volume={pos.volume} side={pos.trade_side}></VolumeSpan></td>
                            <td>{dayjs(pos.create_timestamp).format("HH:mm D MMM")}</td>
                           <td><PriceSpan price={pos.entry_price} isOpen={true}></PriceSpan></td>
                           <td>
                                <PnLSpan PnL={pos.gross_profit} money_digits={money_digits}></PnLSpan>
                            </td>
                            <td>
                                <PnLSpan PnL={pos.net_profit} money_digits={money_digits} ></PnLSpan>
                            </td>
                        </tr>
                    )}
                    <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td className="fw-bold">
                            <PnLSpan PnL={totalOpenGross} money_digits={money_digits}></PnLSpan>
                        </td>
                        <td className="fw-bold">
                            <PnLSpan PnL={totalOpenNet} money_digits={money_digits} ></PnLSpan>
                        </td>
                    </tr>
                </tbody>
            }
            {openPositions.length > 0 && closedPositions.length > 0 &&
                <tbody>
                    <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td className="fw-bold pt-3">
                            <PnLSpan PnL={totalGross} money_digits={money_digits}></PnLSpan>
                        </td>
                        <td className="fw-bold pt-3">
                            <PnLSpan PnL={totalNet} money_digits={money_digits} ></PnLSpan>
                        </td>
                    </tr>

                </tbody>
            }
        </table>
    </div>
}

function PriceSpan({price, isOpen}) {
    if (isOpen) {
        return <span className="text-white me-1" >{price}</span>
    }
    return <span className="text-white opacity-40 me-1" >{price}</span>
}

function VolumeSpan({ volume, side }) {
    const vol = volume / (Math.pow(10, 7));
    if (side === "BUY") {
        return <span className="text-info"><FontAwesomeIcon icon={faCaretUp}/> {vol}</span>
    }
    if (side === "SELL") {
        return <span className="text-danger"><FontAwesomeIcon icon={faCaretDown}/> {vol}</span>
    }
    return <span>  {vol}</span>
}

function PnLSpan({ PnL, money_digits = 2, prefix = '' }) {
    let color = "text-white";
    const integerPnL = parseInt(PnL)
    if (integerPnL > 0) {
        color = "text-success"
    }
    if (integerPnL < 0) {
        color = "text-danger"
    }

    const formattedPnL = (integerPnL / Math.pow(10, money_digits)).toFixed(2)
    return <span className={color}>{prefix}{formattedPnL}</span>

}